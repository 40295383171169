<template>
    <button id="HotspotLabelsButton" v-if="!onHomePage" @click="toggleHotspotLabels" :class="{'HotspotLabelsButton--show': isHotspotLabelsVisible}" aria-hidden="true"></button>
</template>
<script>
export default {
    props: ["onHomePage"],
    methods: {
        toggleHotspotLabels() {
            this.$store.commit('toggleShowHotspotLabels')
        }
    },
    computed: {
        isHotspotLabelsVisible() {
            return this.$store.getters.isHotspotLabelsVisible;
        }
    }
}
</script>
<style lang="scss">
    #HotspotLabelsButton {
        left: 110px;
        background-image: url(@/assets/img/ui/hide-hotspot-labels.png);

        &:hover {
            background-image: url(@/assets/img/ui/hide-hotspot-labels-hover.png);
        }

        &.HotspotLabelsButton--show {
            background-image: url(@/assets/img/ui/show-hotspot-labels.png);

            &:hover {
                background-image: url(@/assets/img/ui/show-hotspot-labels-hover.png);
            }
        }
    }
</style>