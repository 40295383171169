<template>
  <transition name="toggleContentComponent">
    <article class="fullscreenVideo" v-if="ready" :class="{'fullscreenVideo--videopaused': isVideoPaused && videoUrl}">
        <video ref="video"
            v-if="videoUrl"
            :autoplay="properties.autoplay"
            :controls="properties.controls"
            :mute="properties.mute"
            :loop="properties.loop"
            :poster="posterUrl"
            @playing="isVideoPaused = false"
            @pause="isVideoPaused = true"
            type="video/mp4"
            playsinline
            webkit-playsinline
            crossorigin>
            <source :src="videoUrl">
        </video>
        <img v-else-if="posterUrl" :src="posterUrl">

        <button class="closeButton" @click="closeComponent"></button>
    </article>
  </transition>
</template>

<script>


export default {
  props: [
    "properties",
    "pageData",
    "slug"
  ],
  data() {
    return {
      ready: false,
      isVideoPaused: true
    }
  },
  mounted() {
    this.ready = true
  },
  methods: {
    closeComponent() {
      this.$emit("closeComponent", this.slug)
    }
  },
  computed: {
    videoUrl() {
      if(this.properties.video)
        return app.api.Utils.getMediaPath(this.properties.video.src)
    },
    posterUrl() {
      if(this.properties.poster)
        return app.api.Utils.getMediaPath(this.properties.poster.src)
    }
  }
};
</script>

<style lang="scss">
.fullscreenVideo {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--main-color-opacity);

  &>video,&>iframe, &>img {
    height: 100%;
  }
  
  iframe {
    aspect-ratio: 16 / 9;
  }

  &::after {
    content: none;
    position: absolute;
    left: 50.2%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 60px;
    height: 60px;
    pointer-events: none;
    background-image: url(@/assets/img/ui/play.svg);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    z-index: 3;
  }
  
  &::before {
    content: none;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 120px;
    height: 120px;
    pointer-events: none;
    border-radius: 1000px;
    background: var(--main-color);
    border: solid 3px var(--secondary-color);
    opacity: 0.7;
    z-index: 3;
  }

  &--videopaused {
      video {
          cursor: pointer;
      }

      &::after,&::before {
          content: '';
      }
  }

  .closeButton {
    right: 40px;
    top: 40px;
  }
}

</style>