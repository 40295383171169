<template>
    <nav id="CustomNavigation">
        <router-link v-for="link in computedLinks" :to="link.route" :key="link.route" class="link" :class="{'link--active': link.active}">{{link.title}}</router-link>
    </nav>
</template>
<script>

export default {
    data() {
        return {
            links: []
        }
    },
    mounted() {
        this.links = this.getLinks().map(link => {
            link.route = app.api.PageManager.getRouteFromID(link.sceneLink);
            return link;
        });
    },
    methods: {
        getLinks() {
            return app.api.PageManager.getCustomComponentFromType("customNavigation").data.links
        }
    },
    computed: {
        computedLinks() {
            this.$route;
            return this.links.map(link => {
                link.active = app.api.PageManager.getCurrentRoute().endsWith(link.route);
                return link;
            })
        }
    }
}
</script>
<style lang="scss">
#CustomNavigation {
    position: absolute;
    right: 163px;
    top: 52px;

}
.link { 
    position: relative;
    padding: 0 15px;
    font-size: 18px;
    color: var(--main-color);
    transition: ease 0.15s color;

    &::before {
        content: '|';
        position: absolute;
        right: -2px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 23px;
        font-weight: 500 !important;
        text-shadow: none !important;
        color: var(--main-color) !important;
    }

    &:last-of-type {
        &::before {
            content: none;
        }
    }

    &--active {
        cursor: default;
        text-shadow:0px 0px 1px var(--main-color);
    }

    &:hover {
        text-shadow:0px 0px 1px var(--main-color);
    }
}
</style>