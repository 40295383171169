<template>
  <transition name="toggleContentComponent">
    <article class="ContentWindow contentTabs" v-if="ready">
        <button class="closeButton" @click="closeComponent"></button>
        <Breadcrumbs></Breadcrumbs>
        <nav class="contentTabs__nav" ref="contentnavwrapper">
            <button class="contentTabs__nav_arrow contentTabs__nav_arrow_prev" @click="contentNavScroll(-1)" v-if="hasContentNavScroll" :class="{'contentTabs__nav_arrow--disabled': !tabCanNavigateLeft}"></button>
            <div ref="contentnav" v-if="tabs.length > 1">
                <button v-for="tabNav in tabs" :key="tabNav.index" class="link" @click="changeTab(tabNav, null)" v-html="tabNav.navigationTitle" :class="{'link--active': tabNav.index === activeTab}" :ref="tabNav.id"></button>
            </div>
            <button class="contentTabs__nav_arrow contentTabs__nav_arrow_next" @click="contentNavScroll(1)" v-if="hasContentNavScroll" :class="{'contentTabs__nav_arrow--disabled': !tabCanNavigateRight}"></button>
        </nav>

        <!-- Text -->
        <div class="contentTabs__text">
            <Segment :segment="computedContentTab.text.content"></Segment>
        </div>
        <!-- Media -->
        <div class="contentTabs__media">
            <transition-group name="slide"
            :enter-active-class="animations[carouselDirection].enter"
            :leave-active-class="animations[carouselDirection].leave">
                <img v-for="media in computedCarousel" :key="media.src" :src="media.src">
            </transition-group>
            <transition name="carouselNavTransition">
                <nav class="contentTabs__media__nav" v-if="tabHasCarousel">
                    <button class="contentTabs__media__nav_arrow contentTabs__media__nav_arrow_prev" @click="prevCarousel"></button>
                    <button class="contentTabs__media__nav_dot" v-for="dot in carouselNavButtons" :key="dot.index" @click="changeCarousel(dot.index)" :class="{'contentTabs__media__nav_dot--active': dot.index === computedContentTab.activeMedia}"></button>
                    <button class="contentTabs__media__nav_arrow contentTabs__media__nav_arrow_next" @click="nextCarousel"></button>
                </nav>
            </transition>
        </div>
    </article>
  </transition>
</template>

<script>
export default {
  props: ["properties", "pageData", "slug"],
  data() {
    return {
      ready: false,
      tabs: [],
      activeTab: 0,
      hasContentNavScroll: false,
      
      tabDirection: "right",
      carouselDirection: "right",
      animations: {
        right: {
          enter: "slideInLeft",
          leave: "slideOutRight",
        },
        left: {
          enter: "slideInRight",
          leave: "slideOutLeft",
        },
        default: {
          enter: "slideInDown",
          leave: "slideOutDown"
        }
      },
    };
  },
  created() {
    this.initTabs();
  },
  async mounted() {
    this.ready = true;

    await this.$nextTick()
    this.checkHasContentNavScroll();
  },
  watch: {
    activeTab() {
      this.tabs.forEach((t) => {
        t.activeMedia = 0;
      });
    },
  },
  methods: {
    initTabs() {
      this.tabs = this.properties.tabs.map((t, i) => {
        t.index = i;
        t.id = `contentTab__${i}`;
        t.activeMedia = 0;
        if(t.media) {
            t.media.map((m, x) => {
              m.index = x;
            let src = app.api.Utils.getMediaPath(m.src);
            m.src = src;
            app.api.Utils.preloadImage(src);
            return m;
          });
        }
        return t;
      });
    },
    changeTab(tab, index) {
      let id = index !== null ? `contentTab__${index}` : tab.id;

      let element = this.$refs[id][0];

      element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });

      this.carouselDirection = "default";
      this.activeTab = tab.index
    },
    changeCarousel(index) {
        this.carouselDirection = this.tabs[this.activeTab].activeMedia > index ? 'right' : "left"
      this.tabs[this.activeTab].activeMedia = index;
    },
    prevCarousel() {
        this.carouselDirection = "right";
      if (this.tabs[this.activeTab].activeMedia - 1 >= 0) {
        this.tabs[this.activeTab].activeMedia -= 1;
      }
    },
    nextCarousel() {
        this.carouselDirection = "left";
      if (this.tabs[this.activeTab].activeMedia + 1 < this.tabs[this.activeTab].media.length) {
        this.tabs[this.activeTab].activeMedia += 1;
      }

      this.tabs[this.activeTab].activeMedia ;
    },
    checkHasContentNavScroll() {
      if(this.$refs.contentnavwrapper.scrollWidth < this.$refs.contentnav.scrollWidth) {
        this.hasContentNavScroll = true;
      }
    },
    contentNavScroll(value) {
      if((!this.tabCanNavigateRight && value > 0) || (!this.tabCanNavigateLeft && value < 0))
        return

      this.changeTab(null, this.activeTab += value)
    },
    closeComponent() {
      this.$emit("closeComponent", this.slug);
    },
  },
  computed: {
    computedContentTab() {
      return this.tabs[this.activeTab];
    },
    computedCarousel() {
      if (this.computedContentTab && this.computedContentTab.media)
        return [
          this.computedContentTab.media[this.computedContentTab.activeMedia],
        ];
    },
    carouselNavButtons() {
      if (this.tabHasCarousel) return this.computedContentTab.media;
    },
    tabHasCarousel() {
      if(this.computedContentTab.media)
        return this.computedContentTab.media.length > 1;
    },
    tabCanNavigateLeft() {
      return this.activeTab !== 0
    },
    tabCanNavigateRight() {
      return this.activeTab !== this.tabs.length - 1
    },
  },
};
</script>

<style lang="scss">
$textwrapperwidth: 610px;

.contentTabs {
    background: #fff;
  
  &__text {
    width: $textwrapperwidth;
    min-width: $textwrapperwidth;
    height: 100%;
    padding: 160px 35px 100px 70px;

    .Segment {
      padding-right: 35px;
    }
  }

  &__nav {
    position: absolute;
    bottom: 46px;
    width: calc($textwrapperwidth - 140px);
    margin: 0 70px;
    
    &>div { 
      white-space: nowrap;
      text-align: left;
      overflow: hidden;
      
      &::-webkit-scrollbar {
        display: none;
      }

      .link {
        &:first-of-type {
          padding-left: 0;
        }
        &:last-of-type {
          padding-right: 0;
        }
      }
    }

    &_arrow {
      position: absolute;
        width: 13px;
        height: 23px;
        background-image: url(@/assets/img/ui/arrow-right-teal.png);
        background-repeat: no-repeat;
        background-position: center;
        top: 50%;
        transform: translateY(-50%);
        transform-origin: 50% 0;
        transition: ease opacity 0.1s;

        &_prev {
          transform: rotate(180deg) translateY(-50%);
          right: 104%;
        }

        &_next {
          left: 105%;
        }

        &--disabled {
          pointer-events: none;
          opacity: 0;
        }
    }
  }

  &__media {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    overflow: hidden;

    img {
        position: absolute;
        height: 100%;
        animation-fill-mode: both;
        animation-duration: 0.4s;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
        user-drag: none;
    }

    &__nav {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: 20px;
      background: var(--dark-grey-opacity);
      height: 45px;
      border-radius: 1000px;
      padding: 0 10px;
      z-index: 2;

      &_arrow {
        width: 13px;
        height: 23px;
        background-image: url(@/assets/img/ui/arrow-right.png);
        background-repeat: no-repeat;
        background-position: center;
        margin: 0 15px;

        &_prev {
          transform: rotate(180deg);
        }
      }

      &_dot {
        position: relative;
        margin: 0 7px;
        width: 15px;
        height: 15px;
        border: solid 2px #fff;
        background-color: rgba(255, 255, 255, 0.007);
        border-radius: 100%;
        transition: ease 0.1s background-color;

        &:hover,
        &--active {
          background-color: #fff;
        }

        &::after {
          content: "";
          cursor: inherit;
          position: absolute;
          width: 220%;
          height: 220%;
          left: 50%;
          top: 50%;
          transform: translateX(-50%) translateY(-50%);
        }
      }
    }

    
  }
}

@keyframes slideInLeft {
  from {
    -webkit-transform: translate3d(-200px, 0, 0);
    transform: translate3d(-200px, 0, 0);
    visibility: visible;
    opacity: 0;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
}

@keyframes slideOutLeft {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(-200px, 0, 0);
    transform: translate3d(-200px, 0, 0);
    opacity: 0;
  }
}

.slideOutLeft {
  -webkit-animation-name: slideOutLeft;
  animation-name: slideOutLeft;
}

@keyframes slideInRight {
  from {
    -webkit-transform: translate3d(200px, 0, 0);
    transform: translate3d(200px, 0, 0);
    visibility: visible;
    opacity: 0;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.slideInRight {
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight;
}

@keyframes slideOutRight {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(200px, 0, 0);
    transform: translate3d(200px, 0, 0);
    opacity: 0;
  }
}

.slideOutRight {
  -webkit-animation-name: slideOutRight;
  animation-name: slideOutRight;
}

@keyframes slideInDown {
  from {
    -webkit-transform: translate3d(0, 50px, 0);
    transform: translate3d(0, 50px, 0);
    visibility: visible;
    opacity: 0;
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
}

@keyframes slideOutDown {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.slideOutDown {
  -webkit-animation-name: slideOutDown;
  animation-name: slideOutDown;
}

.carouselNavTransition-enter-active {
  transition-property: transform, opacity;
  transition-duration: 0.4s;
  transition-timing-function: var(--tk-easeinout);
}

.carouselNavTransition-leave-active {
  transition-property: transform, opacity;
  transition-duration: 0.2s;
  transition-timing-function: var(--tk-easeinout);
}

.carouselNavTransition-enter-from {
  transform: translateY(40px);
  opacity: 0;
}

.carouselNavTransition-leave-to {
    transform: translateY(40px);
    opacity: 0;
}
</style>