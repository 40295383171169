<template>
    <button id="BackButton" @click="navigateBack" aria-label="back"></button>
</template>
<script>
export default {
    methods: {
        navigateBack() {
            app.api.PageManager.navigateToParentScene();
        }
    }
}
</script>
<style lang="scss">
    #BackButton {
        left: 40px;
        background-image: url(@/assets/img/ui/back.png);

        &:hover {
            background-image: url(@/assets/img/ui/back-hover.png);
        }
    }

    .Backbutton--inactive {
        opacity: 0.4;
        pointer-events: none !important;
    }
</style>