export default function Tracking(app) {
    app.api.Tracking = Object.freeze({
        navigation,
        event
    })

    // Navigation with hooks in the $Router
    function navigation(action, property, payload) {
        // expected format:
        // {Action: "click", Property: "https://www.site.dk/url", Payload: "Any string-data associated with the action"}

        let trackingObject = {
            Action: action,
            Property: property,
            ...(payload !== undefined && { Payload: payload })
        }
        
        CreateLog(trackingObject)
    }
    
    // Explicitly called events with js (buttonclicks etc.)
    function event(action, property, payload) {
        let trackingObject = {
            Action: action,
            Property: property,
            ...(payload !== undefined && { Payload: payload })
        }
            
        CreateLog(trackingObject)
    }
    
    function CreateLog(data) {
        console.log(`Tracking => \nAction: ${data.Action}\nProperty: ${data.Property}`);
    }
}