<template>
    <div class="videoSegment" :class="{'videoSegment--hasLink': hasLink}">
        <video @click="openContent" muted autoplay loop>
            <source :src="computedSrc">
        </video>
        <button v-if="content.link"></button>
    </div>
</template>
<script>
export default {
    props: ["content"],
    methods: {
        openContent() {
            if(this.hasLink)
                app.api.PageManager.navigateToRoute(this.getRoute)
        }
    },
    computed: {
        getRoute() {
            if(this.hasLink)
                return app.api.PageManager.getRouteFromIDs(false, this.content.link)
        },
        hasLink() {
            return this.content.link
        },
        computedSrc() {
            if(this.content.video)
                return app.api.Utils.getMediaPath(this.content.video.src)
        }
    }
}
</script>
<style lang="scss">
$button-size: 28px;

.videoSegment {
    position: relative;
    width: 100%;
    margin-bottom: 30px;
    background: var(--light-grey);

    video {
        width: 100%;
    }

    &::after {
        position: absolute;
        left: 50.4%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: $button-size;
        height: $button-size;
        pointer-events: none;
        background-image: url(@/assets/img/ui/play.svg);
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center;
        transform-origin: 0 0;
        transition: transform var(--tk-easeinout) 0.15s;
        z-index: 3;
    }
    
    &::before {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: $button-size * 2;
        height: $button-size * 2;
        pointer-events: none;
        border-radius: 1000px;
        background: var(--main-color);
        border: solid 3px var(--secondary-color);
        opacity: 0.7;
        transform-origin: 0 0;
        transition: transform var(--tk-easeinout) 0.15s;
        z-index: 3;
    }

    &--hasLink {
        cursor: pointer;
        &::before, &::after {
            content: '';
        }
    }    

    &:hover {
        &::after, &::before {
            transform: scale(1.1) translateX(-50%) translateY(-50%);
        }
    }
}

</style>