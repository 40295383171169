<template>
    <h2 class="headlineBig" v-html="content.text"></h2>
</template>
<script>
export default {
    props: ["content"]
}
</script>
<style lang="scss">
.headlineBig {
    position: relative;
    font-size: 45px;
    font-weight: 700;
}
</style>